<template>
  <keep-alive>
    <div class="medicine_digital_travel">
      <div class="mdt_top">
        <img class="mdt_top_img" src="../../../static/img/digital/top_bg.png" alt="" srcset="">
        <div class="mdt_top_video" :class="{video_box: !topVideoPlayer}">
          <img v-if="!topVideoPlayer" class="mdt_top_play" src="../../../static/img/digital/play.png" @click="play('top_video', 'topVideoPlayer')">
          <video ref="top_video" :controls="topVideoPlayer" src="https://pic.lian-ou.com/H5/web/one-page-project/Li_Hanhui.mp4" poster="../../../static/img/digital/to_video_bg.png" />
        </div>
      </div>
      <div class="mdt_ThroughTheSite">
        <mdt-title cn-title="途经站点" en-title="WAY TO SITE" />
        <div class="map_box">
          <img src="../../../static/img/digital/map.png" alt="" srcset="">
        </div>
        <div class="site">
          <div class="current_site">
            <p class="current_site_title">目前站点</p>
            <!-- <span class="main_bg site_address">陕西西安 | 利君制药</span> -->
            <!-- <span class="main_bg site_address">河南驻马店 | 龟鹿药业</span> -->
            <!-- <span class="main_bg site_address">重庆 | 康刻尔制药</span> -->
            <!-- <span class="main_bg site_address">陕西西安 | 怡康医药</span> -->
            <!-- <span class="main_bg site_address">陕西西安 | 四美营销策划</span> -->
            <span class="main_bg site_address">上海 | 桑迪营销</span>
          </div>
        <!-- <div class="current_site next_site"> -->
        <!-- <p class="current_site_title">下一站</p> -->
        <!-- <span class="main_bg site_address">陕西｜利君药业</span> -->
        <!-- <span class="main_bg site_address">重庆 | 重庆万和</span><br> -->
        <!-- <span class="main_bg site_address">陕西西安 | 海天制药</span><br> -->
        <!-- <span class="main_bg site_address">陕西西安 | 力邦制药</span><br> -->
        <!-- <span class="main_bg site_address">陕西西安 | 步长药业</span> -->
        <!-- </div> -->
        </div>
      </div>
      <div class="mdt_DigitalJourneyYeosuStation">
        <mdt-title cn-title="数字化万里行·上海站" en-title="DIGITAL MILES" />
        <div class="mdt_djys_video_box">
          <div class="mdt_djys_video" :class="{video_box: !djysVideoPlayer}">
            <img v-if="!djysVideoPlayer" class="mdt_djys_play" src="../../../static/img/digital/play.png" alt="" srcset="" @click="play('djys_video', 'djysVideoPlayer')">
            <video ref="djys_video" :controls="djysVideoPlayer" src="https://pic.lian-ou.com/H5/web/one-page-project/sangdi2.mp4" poster="../../../static/img/digital/djys_video_bg.png" />
          </div>
          <div class="mdt_djys_title">
            上海站 | 处方药如何借力短视频推广?
            <div class="mdt_djys_position">
              <span class="colorEBB7FF">张继明董事长</span>
              <span class="color_light">桑迪营销</span>
            </div>
          </div>
        </div>
        <div class="mdt_djys_img_box">
          <swiper effect="cube" :pagination="{ clickable: true }">
            <swiper-slide class="flex_between">
              <img class="mdt_diys_img" src="../../../static/img/digital/sangdi/sangdi1.jpg" @click="openBigImg(require('../../../static/img/digital/sangdi/sangdi1.jpg'), 1)">
              <img class="mdt_diys_img" src="../../../static/img/digital/sangdi/sangdi2.jpg" @click="openBigImg(require('../../../static/img/digital/sangdi/sangdi2.jpg'), 2)">
              <img class="mdt_diys_img" src="../../../static/img/digital/sangdi/sangdi3.jpg" @click="openBigImg(require('../../../static/img/digital/sangdi/sangdi3.jpg'), 3)">
            </swiper-slide>
            <swiper-slide class="flex_between">
              <img class="mdt_diys_img" src="../../../static/img/digital/sangdi/sangdi4.jpg" @click="openBigImg(require('../../../static/img/digital/sangdi/sangdi4.jpg'), 4)">
              <img class="mdt_diys_img" src="../../../static/img/digital/sangdi/sangdi5.jpg" @click="openBigImg(require('../../../static/img/digital/sangdi/sangdi5.jpg'), 5)">
              <img class="mdt_diys_img" src="../../../static/img/digital/sangdi/sangdi6.jpg" @click="openBigImg(require('../../../static/img/digital/sangdi/sangdi6.jpg'), 6)">
            </swiper-slide>
            <!-- <swiper-slide class="flex_between">
              <img class="mdt_diys_img" src="../../../static/img/digital/zongxin/zongxin7.jpg" @click="openBigImg(require('../../../static/img/digital/zongxin/zongxin7.jpg'), 7)">
              <img class="mdt_diys_img" src="../../../static/img/digital/zongxin/zongxin8.jpg" @click="openBigImg(require('../../../static/img/digital/zongxin/zongxin8.jpg'), 8)">
              <img class="mdt_diys_img" src="../../../static/img/digital/zongxin/zongxin9.jpg" @click="openBigImg(require('../../../static/img/digital/zongxin/zongxin9.jpg'), 9)">
            </swiper-slide>
            <swiper-slide class="flex_between">
              <img class="mdt_diys_img" src="../../../static/img/digital/zongxin/zongxin10.jpg" @click="openBigImg(require('../../../static/img/digital/zongxin/zongxin10.jpg'), 10)">
              <img class="mdt_diys_img" style="opacity: 0;">
              <img class="mdt_diys_img" style="opacity: 0;">
            </swiper-slide> -->
          <!-- <swiper-slide class="flex_between">
            <img class="mdt_diys_img" src="../../../static/img/digital/yikang/yikang13.jpg" @click="openBigImg(require('../../../static/img/digital/yikang/yikang13.jpg'), 13)">
            <img class="mdt_diys_img" src="../../../static/img/digital/yikang/yikang14.jpg" @click="openBigImg(require('../../../static/img/digital/yikang/yikang14.jpg'), 14)">
            <img class="mdt_diys_img" src="../../../static/img/digital/yikang/yikang15.jpg" @click="openBigImg(require('../../../static/img/digital/yikang/yikang15.jpg'), 15)">
          </swiper-slide>
          <swiper-slide class="flex_between">
            <img class="mdt_diys_img" src="../../../static/img/digital/yikang/yikang16.jpg" @click="openBigImg(require('../../../static/img/digital/yikang/yikang16.jpg'), 16)">
            <img class="mdt_diys_img" src="../../../static/img/digital/yikang/yikang17.jpg" @click="openBigImg(require('../../../static/img/digital/yikang/yikang17.jpg'), 17)">
            <img class="mdt_diys_img" src="../../../static/img/digital/yikang/yikang18.jpg" @click="openBigImg(require('../../../static/img/digital/yikang/yikang18.jpg'), 18)">
          </swiper-slide>
          <swiper-slide class="flex_between">
            <img class="mdt_diys_img" src="../../../static/img/digital/yikang/yikang19.jpg" @click="openBigImg(require('../../../static/img/digital/yikang/yikang19.jpg'), 19)">
            <img class="mdt_diys_img" src="../../../static/img/digital/yikang/yikang20.jpg" @click="openBigImg(require('../../../static/img/digital/yikang/yikang20.jpg'), 20)">
            <img class="mdt_diys_img" src="../../../static/img/digital/yikang/yikang21.jpg" @click="openBigImg(require('../../../static/img/digital/yikang/yikang21.jpg'), 21)">
          </swiper-slide>
          <swiper-slide class="flex_between">
            <img class="mdt_diys_img" src="../../../static/img/digital/yikang/yikang22.jpg" @click="openBigImg(require('../../../static/img/digital/yikang/yikang22.jpg'), 22)">
            <img class="mdt_diys_img" src="../../../static/img/digital/yikang/yikang23.jpg" @click="openBigImg(require('../../../static/img/digital/yikang/yikang23.jpg'), 23)">
            <img class="mdt_diys_img" src="../../../static/img/digital/yikang/yikang24.jpg" @click="openBigImg(require('../../../static/img/digital/yikang/yikang24.jpg'), 24)">
          </swiper-slide>
          <swiper-slide class="flex_between">
            <img class="mdt_diys_img" src="../../../static/img/digital/yikang/yikang25.jpg" @click="openBigImg(require('../../../static/img/digital/yikang/yikang25.jpg'), 25)">
            <img class="mdt_diys_img" src="../../../static/img/digital/yikang/yikang26.jpg" @click="openBigImg(require('../../../static/img/digital/yikang/yikang26.jpg'), 26)">
            <img class="mdt_diys_img" src="../../../static/img/digital/yikang/yikang27.jpg" @click="openBigImg(require('../../../static/img/digital/yikang/yikang27.jpg'), 27)">
          </swiper-slide>
          <swiper-slide class="flex_between">
            <img class="mdt_diys_img" src="../../../static/img/digital/yikang/yikang28.jpg" @click="openBigImg(require('../../../static/img/digital/yikang/yikang28.jpg'), 28)">
            <img class="mdt_diys_img" src="../../../static/img/digital/yikang/yikang29.jpg" @click="openBigImg(require('../../../static/img/digital/yikang/yikang29.jpg'), 29)">
            <img class="mdt_diys_img" style="opacity: 0;">
          </swiper-slide> -->
          </swiper>
        </div>
      </div>
      <div class="mdt_highlights">
        <mdt-title cn-title="精彩回顾" en-title="WONDERFUL REVIEW" />
        <div class="mdt_highlights_video_box flex_between">
          <div v-for="(item, index) in mdtHighlightsList" :key="index" class="mdt_highlights_box" @click="toVideo(item.src, item.title)">
            <img class="mdt_highlights_play" src="../../../static/img/digital/play.png" alt="" srcset="">
            <div class="video_box">
              <img class="mdt_highlights_video" :src="item.videoBj">
            <!-- <video class="mdt_highlights_video" :src="item.src" :poster="item.videoBj" /> -->
            </div>
            <div class="mdt_highlights_title_box">
              <div :class="{mdt_highlights_title: index <= 2}">{{ item.title }}</div>
              <div class="mdt_highlights_position">
                <span class="colorEBB7FF">{{ item.name }}</span>
                <span class="color_light">{{ item.company }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="mdt_Hall_of_Fame">
      <mdt-title cn-title="万里行·名人堂" en-title="HALL OF FAME" />
      <div class="Hall_of_Fame flex_between">
        <div v-for="(item, index) in hallOfFameList" :key="index" class="Hall_of_Fame_box">
          <div class="Hall_of_Fame_top">
            <div class="Hall_of_Fame_img"><img v-if="item.src" :src="item.src" alt="" srcset=""><span v-else>敬请期待</span></div>
            <div class="Hall_of_Fame_bg" />
          </div>
          <div class="Hall_of_Fame_bottom">
            <div class="Hall_of_Fame_name">
              <span class="hof_name">{{ item.name }}</span>
              <span class="hof_position">{{ item.position }}</span>
            </div>
            <div class="hof_company">{{ item.company }}</div>
          </div>
        </div>
      </div>
    </div> -->
      <div class="mdt_partners">
        <mdt-title cn-title="合作伙伴" en-title="COOPERATIVE PARTNER" />
        <div class="flex_between">
          <img class="partners_img" src="../../../static/img/digital/qiye/sangdi.png" alt="" srcset="">
          <img class="partners_img" src="../../../static/img/digital/qiye/zhongxin.png" alt="" srcset="">
          <img class="partners_img" src="../../../static/img/digital/qiye/simei.png" alt="" srcset="">
          <img class="partners_img" src="../../../static/img/digital/qiye/yikang.png" alt="" srcset="">
          <img class="partners_img" src="../../../static/img/digital/qiye/lijun.png" alt="" srcset="">
          <img class="partners_img" src="../../../static/img/digital/qiye/guiluyaoye.png" alt="" srcset="">
          <img class="partners_img" src="../../../static/img/digital/qiye/kangkeer.png" alt="" srcset="">
          <img class="partners_img" src="../../../static/img/digital/qiye/btn.png" alt="" srcset="">
          <img class="partners_img" src="../../../static/img/digital/qiye/kunyaojituan.png" alt="" srcset="">
          <img class="partners_img" src="../../../static/img/digital/qiye/huarunsanjiu.png" alt="" srcset="">
          <img class="partners_img" src="../../../static/img/digital/qiye/weikang.png" alt="" srcset="">
        </div>
      </div>
      <div class="mdt_digitization">
        <mdt-title cn-title="莲藕科技助力行业数字化" en-title="DIGITIZATION" />
        <div class="digitization flex_between">
          <img class="digitization_img" src="../../../static/img/digital/digitization1.png" alt="" srcset="">
          <img class="digitization_img" src="../../../static/img/digital/digitization2.png" alt="" srcset="">
          <img class="digitization_img" src="../../../static/img/digital/digitization3.png" alt="" srcset="">
          <img class="digitization_img" src="../../../static/img/digital/digitization4.png" alt="" srcset="">
        </div>
      </div>
      <div class="mdt_with">
        <mdt-title cn-title="同行有你" en-title="WITH" />
        <div class="with">
          <div class="with_top">医药数字化万里行，诚邀您-同为医药行业数字化发展带来新视野、新玩法、新模式</div>
          <div class="with_bottom">
            <img class="with_qrcode" src="../../../static/img/digital/位图@2x.png" alt="" srcset="">
            <div class="with_card">
              <p>行业专家</p>
              <p>联系人：王先生</p>
              <p>联系方式：15920570383</p>
              <p>邮箱：764058491@qq.com</p>
            </div>
          </div>
        </div>
      </div>
      <div class="mdt_bottom">
        <img class="mdt_bottom_img" src="../../../static/img/digital/page_bottom.png" alt="" srcset="">
      </div>
      <div v-if="bigImgBol && (bigImgIndex != null)" class="big_img" @click="bigImgBol = false">
        <swiper ref="bigImgSwiper" class="bigImgSwiper" effect="cube" :initial-slide="bigImgIndex">
          <swiper-slide v-for="(item, index) in imgList" :key="index" class="flex_between">
            <img :src="item">
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </keep-alive>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper'
import 'swiper/swiper-bundle.css'
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y])
import Title from './title'
export default {
  components: {
    Swiper, SwiperSlide,
    'mdt-title': Title
  },
  data() {
    return {
      bigImgBol: false,
      bigImgIndex: null,
      bigImgSrc: '',
      topVideoPlayer: false,
      djysVideoPlayer: false,
      mdtHighlightsList: [
        {
          title: '处方药如何借力短视频推广?',
          name: '张继明董事长',
          company: '桑迪营销',
          videoBj: require('../../../static/img/digital/sangdi2.png'),
          src: 'https://pic.lian-ou.com/H5/web/one-page-project/sangdi2.mp4'
        },
        {
          title: '如何实现品牌价值再绽放?',
          name: '张继明董事长',
          company: '桑迪营销',
          videoBj: require('../../../static/img/digital/sangdi.png'),
          src: 'https://pic.lian-ou.com/H5/web/one-page-project/sangdi.mp4'
        },
        {
          title: '线上“价格战”实体药店也一样敢打?',
          name: '乔元辉董事长',
          company: '众信医药',
          videoBj: require('../../../static/img/digital/zongxin2.png'),
          src: 'https://pic.lian-ou.com/H5/web/one-page-project/zongxin2.mp4'
        },
        {
          title: '药店面临洗牌，如何守正创新?',
          name: '乔元辉董事长',
          company: '众信医药',
          videoBj: require('../../../static/img/digital/zongxin.png'),
          src: 'https://pic.lian-ou.com/H5/web/one-page-project/zongxin.mp4'
        },
        {
          title: '职业经理人需要哪些特质?',
          name: '张新婷董事长',
          company: '四美营销',
          videoBj: require('../../../static/img/digital/simei2.png'),
          src: 'https://pic.lian-ou.com/H5/web/one-page-project/simei2.mp4'
        },
        {
          title: '口碑传播如何借力数字化营销?',
          name: '张新婷董事长',
          company: '四美营销',
          videoBj: require('../../../static/img/digital/simei.png'),
          src: 'https://pic.lian-ou.com/H5/web/one-page-project/simei.mp4'
        },
        {
          title: '医药龙头企业智慧药房私域如何玩?',
          name: '刘辉副总经理',
          company: '怡康医药',
          videoBj: require('../../../static/img/digital/yikang3.png'),
          src: 'https://pic.lian-ou.com/H5/web/one-page-project/yikang3.mp4'
        },
        {
          title: '医药龙头企业如何实现线上线下一体化?',
          name: '刘辉副总经理',
          company: '怡康医药',
          videoBj: require('../../../static/img/digital/yikang2.png'),
          src: 'https://pic.lian-ou.com/H5/web/one-page-project/yikang2.mp4'
        },
        {
          title: '医药龙头企业如何布局新零售?',
          name: '刘辉副总经理',
          company: '怡康医药',
          videoBj: require('../../../static/img/digital/yikang.png'),
          src: 'https://pic.lian-ou.com/H5/web/one-page-project/yikang.mp4'
        },
        {
          title: '传统企业数字化转型要做好哪三件事?',
          name: '司杰总经理',
          company: '利君制药',
          videoBj: require('../../../static/img/digital/lijun2.png'),
          src: 'https://pic.lian-ou.com/H5/web/one-page-project/lijun2.mp4'
        },
        {
          title: '传统企业数字化转型是赚钱？还是花钱?',
          name: '司杰总经理',
          company: '利君制药',
          videoBj: require('../../../static/img/digital/lijun1.png'),
          src: 'https://pic.lian-ou.com/H5/web/one-page-project/lijun1.mp4'
        },
        {
          title: '如何撬动龟鹿胶百亿市场',
          name: '郭兵总经理助理',
          company: '龟鹿药业',
          videoBj: require('../../../static/img/digital/guiluyaoye4.png'),
          src: 'https://pic.lian-ou.com/H5/web/one-page-project/guiluyaoye4.mp4'
        },
        {
          title: '现代中药如何做好数字化营销布局',
          name: '郭兵总经理助理',
          company: '龟鹿药业',
          videoBj: require('../../../static/img/digital/guiluyaoye3.png'),
          src: 'https://pic.lian-ou.com/H5/web/one-page-project/guiluyaoye3.mp4'
        },
        {
          title: '传统中药企如何做大品牌?',
          name: '郭兵总经理助理',
          company: '龟鹿药业',
          videoBj: require('../../../static/img/digital/guiluyaoye2.png'),
          src: 'https://pic.lian-ou.com/H5/web/one-page-project/guiluyaoye2.mp4'
        },
        {
          title: '龟鹿药业的智能化建设',
          name: '郭兵总经理助理',
          company: '龟鹿药业',
          videoBj: require('../../../static/img/digital/guiluyaoye.png'),
          src: 'https://pic.lian-ou.com/H5/web/one-page-project/guiluyaoye.mp4'
        },
        {
          title: '拥抱糖尿病数字疗法',
          name: '陈用芳董事长',
          company: '康刻尔制药',
          videoBj: require('../../../static/img/digital/康刻尔2.png'),
          src: 'https://pic.lian-ou.com/H5/web/one-page-project/Kang_carved2.mp4'
        },
        {
          title: '为世界糖尿病患者提供中国方案',
          name: '陈用芳董事长',
          company: '康刻尔制药',
          videoBj: require('../../../static/img/digital/康刻尔1.png'),
          src: 'https://pic.lian-ou.com/H5/web/one-page-project/Kang_carved.mp4'
        },
        {
          title: '从大单品到大品类聚焦薇诺娜大品牌战略',
          name: '郭振宇董事长',
          company: '贝泰妮集团',
          videoBj: require('../../../static/img/digital/郭振宇董事长1.png'),
          src: 'https://pic.lian-ou.com/H5/web/one-page-project/Interview_with_Guo_Zhenyu5.mp4'
        },
        {
          title: '内容营销就该这样玩!',
          name: '郭振宇董事长',
          company: '贝泰妮集团',
          videoBj: require('../../../static/img/digital/郭振宇董事长4.png'),
          src: 'https://pic.lian-ou.com/H5/web/one-page-project/Interview_with_Guo_Zhenyu4.mp4'
        },
        {
          title: '数字化运营就该这样玩!',
          name: '郭振宇董事长',
          company: '贝泰妮集团',
          videoBj: require('../../../static/img/digital/郭振宇董事长3.png'),
          src: 'https://pic.lian-ou.com/H5/web/one-page-project/Interview_with_Guo_Zhenyu3.mp4'
        },
        {
          title: '私域运营就该这样玩!',
          name: '郭振宇董事长',
          company: '贝泰妮集团',
          videoBj: require('../../../static/img/digital/郭振宇董事长2.png'),
          src: 'https://pic.lian-ou.com/H5/web/one-page-project/Interview_with_Guo_Zhenyu2.mp4'
        },
        {
          title: '品牌数字化营销应该这样玩!',
          name: '郭振宇董事长',
          company: '贝泰妮集团',
          videoBj: require('../../../static/img/digital/郭振宇董事长1.png'),
          src: 'https://pic.lian-ou.com/H5/web/one-page-project/Interview_with_Guo_Zhenyu1.mp4'
        },
        {
          title: '问道昆药集团数字化战略',
          name: '钟祥刚总裁',
          company: '昆药集团',
          videoBj: require('../../../static/img/digital/钟祥刚总裁.png'),
          src: 'https://pic.lian-ou.com/H5/web/one-page-project/Interview_with_Zhong_Xianggang.mp4'
        },
        {
          title: '数字中国战略背景下品牌药企如何破局',
          name: '邱凯总经理',
          company: '华润圣火药业',
          videoBj: require('../../../static/img/digital/邱凯总经理.png'),
          src: 'https://pic.lian-ou.com/H5/web/one-page-project/Interview_with_Qiu_Kai.mp4'
        },
        {
          title: '药店数字化营销新玩法',
          name: '苏德勇总经理',
          company: '维康医药零售',
          videoBj: require('../../../static/img/digital/苏德勇总经理.png'),
          src: 'https://pic.lian-ou.com/H5/web/one-page-project/Interview_with_Su_Deyong.mp4'
        },
        {
          title: '维康“好声音”唱响C端撬动B端',
          name: '孔晓霞总裁',
          company: '维康药业',
          videoBj: require('../../../static/img/digital/孔晓霞总裁.png'),
          src: 'https://pic.lian-ou.com/H5/web/one-page-project/Interview_with_Kong_Xiaoxia.mp4'
        },
        {
          title: '数字化时代， 品牌如何破圈',
          name: '王燕雄董事长',
          company: '莲藕健康',
          videoBj: require('../../../static/img/digital/王燕雄董事长.png'),
          src: 'https://pic.lian-ou.com/H5/web/one-page-project/Wang_Yanxiong_last_set.mp4'
        },
        {
          title: '医药行业创新，数字化重塑产业',
          name: '王燕雄董事长',
          company: '莲藕健康',
          videoBj: require('../../../static/img/digital/王燕雄董事长2.png'),
          src: 'https://pic.lian-ou.com/H5/web/one-page-project/Wang_Yanxiong_fixation_set.mp4'
        }
      ],
      hallOfFameList: [
        {
          src: require('../../../static/img/digital/top_bg1.png'),
          name: '王燕雄',
          position: '董事长',
          company: '莲藕健康'
        },
        {
          src: require('../../../static/img/digital/top_bg1.png'),
          name: '苏德勇',
          position: '总经理',
          company: '维康医药零售'
        },
        {
          src: require('../../../static/img/digital/top_bg1.png'),
          name: '孔晓霞',
          position: '总裁',
          company: '维康药业'
        },
        {
          src: require('../../../static/img/digital/top_bg1.png'),
          name: '邱凯',
          position: '总经理',
          company: '华润圣火药业'
        },
        {
          src: require('../../../static/img/digital/top_bg1.png'),
          name: '钟祥刚',
          position: '总裁',
          company: '昆药集团'
        },
        {}
      ],
      videoList: [
        'https://pic.lian-ou.com/H5/web/one-page-project/3-%E5%9C%A8%E6%95%B0%E5%AD%97%E5%8C%96%E8%90%A5%E9%94%80%E5%B1%82%E9%9D%A2%EF%BC%8C%E6%9C%89%E5%93%AA%E4%BA%9B%E5%88%9B%E6%96%B0%E4%B8%BE%E6%8E%AA%EF%BC%9F.mp4',
        'https://pic.lian-ou.com/H5/web/one-page-project/%E5%AD%94%E6%99%93%E9%9C%9E%E4%B8%93%E8%AE%BF-%E4%BA%8C%E7%BB%B4%E7%A0%81-.mp4',
        'https://pic.lian-ou.com/H5/web/one-page-project/2021.7.6_%E6%B1%89%E8%BE%89%E6%80%BB%E6%8A%96%E9%9F%B3%E7%89%88001.mp4',
        'https://pic.lian-ou.com/H5/web/one-page-project/2021.7.9%E6%B1%89%E8%BE%89%E6%80%BB%E9%87%87%E8%AE%BF%E8%A7%86%E9%A2%91%E5%8F%B702%EF%BC%88%E7%BB%93%E5%B0%BE%E5%8A%A0%E4%BA%8C%E7%BB%B4%E7%A0%81%EF%BC%89.mp4'
      ],
      imgList: [
        require('../../../static/img/digital/sangdi/sangdi1.jpg'),
        require('../../../static/img/digital/sangdi/sangdi2.jpg'),
        require('../../../static/img/digital/sangdi/sangdi3.jpg'),
        require('../../../static/img/digital/sangdi/sangdi4.jpg'),
        require('../../../static/img/digital/sangdi/sangdi5.jpg'),
        require('../../../static/img/digital/sangdi/sangdi6.jpg')
        // require('../../../static/img/digital/zongxin/zongxin7.jpg'),
        // require('../../../static/img/digital/zongxin/zongxin8.jpg'),
        // require('../../../static/img/digital/zongxin/zongxin9.jpg'),
        // require('../../../static/img/digital/zongxin/zongxin10.jpg')
        // require('../../../static/img/digital/yikang/yikang11.jpg'),
        // require('../../../static/img/digital/yikang/yikang12.jpg'),
        // require('../../../static/img/digital/yikang/yikang13.jpg'),
        // require('../../../static/img/digital/yikang/yikang14.jpg'),
        // require('../../../static/img/digital/yikang/yikang15.jpg'),
        // require('../../../static/img/digital/yikang/yikang16.jpg'),
        // require('../../../static/img/digital/yikang/yikang17.jpg'),
        // require('../../../static/img/digital/yikang/yikang18.jpg'),
        // require('../../../static/img/digital/yikang/yikang19.jpg'),
        // require('../../../static/img/digital/yikang/yikang20.jpg'),
        // require('../../../static/img/digital/yikang/yikang21.jpg'),
        // require('../../../static/img/digital/yikang/yikang22.jpg'),
        // require('../../../static/img/digital/yikang/yikang23.jpg'),
        // require('../../../static/img/digital/yikang/yikang24.jpg'),
        // require('../../../static/img/digital/yikang/yikang25.jpg'),
        // require('../../../static/img/digital/yikang/yikang26.jpg'),
        // require('../../../static/img/digital/yikang/yikang27.jpg'),
        // require('../../../static/img/digital/yikang/yikang28.jpg'),
        // require('../../../static/img/digital/yikang/yikang29.jpg')
      ]
    }
  },
  methods: {
    toVideo(src, title) {
      this.$router.push({
        path: 'video',
        query: { src, title }
      })
    },
    play(videoRef, playBol) {
      this[playBol] = true
      this.pauseAll()
      this.$refs[videoRef].play()
      // 监听视频播放
      this.$refs[videoRef].addEventListener('play', () => {
        console.log('video is playing', playBol)
        // this.openTimer()
      })
      // 监听视频暂停
      this.$refs[videoRef].addEventListener('pause', () => {
        console.log('video is stop', playBol)
        this[playBol] = false
        // this.closeTimer()
      })
      this.$refs[videoRef].addEventListener('timeupdate', () => {
        // console.log('video is timeupdate', playBol)
        if (!this[playBol]) { this[playBol] = true; this.$refs[videoRef].play() }
        // this[playBol] = true
        // this.$refs[videoRef].play()
        // this.closeTimer()
      })
    },
    pauseAll() {
      for (const key in this.$refs) {
        if (Object.hasOwnProperty.call(this.$refs, key)) {
          const element = this.$refs[key]
          element.pause()
        }
      }
    },
    launchFullscreen(element) {
      // 此方法不可以在異步任務中執行，否則火狐無法全屏
      if (element.requestFullscreen) {
        element.requestFullscreen()
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen()
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen()
      } else if (element.oRequestFullscreen) {
        element.oRequestFullscreen()
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullScreen()
      } else {
        var docHtml = document.documentElement
        var docBody = document.body
        var videobox = document.getElementById('video')
        var cssText = 'width:100%;height:100%;overflow:hidden;'
        docHtml.style.cssText = cssText
        docBody.style.cssText = cssText
        videobox.style.cssText = cssText + ';' + 'margin:0px;padding:0px;'
        document.IsFullScreen = true
      }
    },
    openBigImg(src, index) {
      this.bigImgBol = true
      this.bigImgIndex = index - 1
    },
    closeBigImg() {
      this.bigImgBol = false
      this.bigImgIndex = null
    }
  }
}
</script>

<style lang="scss" scoped>
@function torem($px){ //$px为需要转换的字号
    @return $px / 100 + rem; //100px为根字体大小
}
.medicine_digital_travel {
  background: #1B0F64;
  font-size: 0;
  letter-spacing: torem(2);
  .mdt_top {
    position: relative;
    &_img {
      width: 100%;
    }
    &_video {
      position: absolute;
      bottom: torem(35);
      left: torem(28);
      right: torem(28);
      width: torem(319);
      height: torem(319);
      margin: 0 auto;
      background: #000;
      .mdt_top_play {
        width: torem(42);
        height: torem(42);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
      }
      >video {
        width: 100%;
        height: 100%;
      }
    }
  }
  .mdt_ThroughTheSite {
    .map_box {
      margin: 0 torem(32);
      >img {
        width: 100%;
      }
    }
    .site {
      .current_site {
        color: #fff;
        font-size: torem(13);
        line-height: torem(20);
        &_title {
          margin-top: torem(13);
          margin-bottom: torem(8);
          line-height: torem(17);
        }
        .site_address {
          margin-bottom: torem(10);
          &:nth-last-child(1) {
            margin-bottom: 0;
          }
        }
      }
      .next_site {
        margin-top: torem(30);
      }
    }
  }
  .mdt_DigitalJourneyYeosuStation {
    .mdt_djys_video_box {
      border-bottom: torem(1) solid #685BB5;
      padding-bottom: torem(14);
      margin: 0 torem(15);
      .mdt_djys_video {
        width: torem(319);
        height: torem(319);
        margin: 0 auto;
        background: #000;
        display: flex;
        position: relative;
        >video {
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          position: absolute;
        }
      }
      .mdt_djys_play {
        display: block;
        width: torem(40);
        height: torem(40);
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2;
        transform: translate(-50%, -50%);
      }
      .mdt_djys_title {
        color: #fff;
        font-size: torem(15);
        margin-top: torem(12);
        position: relative;
      }
      .mdt_djys_position {
        font-size: torem(12);
        margin-top: torem(9);
      }

    }
    .mdt_djys_img_box {
      padding: torem(15) torem(15) 0;
      .swiper-container {
        height: torem(70);
        padding-bottom: torem(18);
        /deep/.swiper-pagination {
          bottom: 0;
          .swiper-pagination-bullet {
            width: torem(4);
            height: torem(4);
            margin: 0 torem(2.5);
            border-radius: 0;
            background: #8278C0;
            opacity: 1;
          }
          .swiper-pagination-bullet-active {
            width: torem(23);
            background: linear-gradient(270deg, rgba(146, 13, 159, 0) 0%, #B622ED 100%);
          }
        }
      }
      .swiper-slide {
        img {
          width: torem(111) !important;
          height: torem(70);
          background: pink;
        }
        .mdt_diys_img {
          width: torem(111) !important;
          height: torem(70);
        }
      }
    }
  }
  .mdt_highlights {
    margin: 0 torem(15);
    .mdt_highlights_video_box {
      flex-wrap: wrap;
      align-items: flex-start;
      .mdt_highlights_box {
        width: torem(166);
        font-size: torem(13);
        color: #fff;
        position: relative;
        margin-bottom: torem(28);
        .mdt_highlights_play {
          position: absolute;
          width: torem(20);
          height: torem(20);
          top: torem(10);
          left: torem(10);
          z-index: 2;
        }
        .mdt_highlights_title_box {
          min-height: torem(76);
          padding: torem(10) torem(8) torem(12);
          box-sizing: border-box;
          text-align: left;
          font-size: torem(13);
          background: #504786;
          letter-spacing: torem(1);
          .mdt_highlights_title {
            min-height: torem(34);
          }
          .mdt_highlights_position {
            font-size: torem(12);
            margin-top: torem(6);
          }
        }
        .mdt_highlights_video {
          width: 100%;
          height: torem(109);
          background: #000;
          display: block;
          position: relative;
        }
        &:nth-last-child(1), &:nth-last-child(2) {
          margin-bottom: 0;
        }
      }
    }
  }
  .mdt_Hall_of_Fame {
    .Hall_of_Fame {
      flex-wrap: wrap;
      align-items: flex-start;
      padding: 0 torem(15);
      .Hall_of_Fame_box {
        margin-bottom: torem(25);
        &:nth-last-child(1), &:nth-last-child(2), &:nth-last-child(3) {
          margin-bottom: 0;
        }
        .Hall_of_Fame_top {
          width: torem(94);
          position: relative;
          padding-top: torem(27);
          padding-left: torem(20);
          box-sizing: border-box;
          .Hall_of_Fame_img {
            position: absolute;
            font-size: torem(12);
            color: #fff;
            top: 0;
            left: 0;
            width: torem(85);
            height: torem(111);
            background: linear-gradient(180deg, #5440CF 0%, #1B0F64 100%);
            display: flex;
            align-items: center;
            justify-content: center;
            >img {
              display: inline-flex;
              width: 100%;
              height: 100%;
            }
          }
          .Hall_of_Fame_bg {
            width: torem(74);
            height: torem(91);
            background: linear-gradient(270deg, #B622ED 0%, rgba(146, 13, 159, 0) 100%);
          }
        }
        .Hall_of_Fame_bottom {
          text-align: left;
          margin-top: torem(9);
          .Hall_of_Fame_name {
            color: #ffffff;
            font-size: torem(14);
            .hof_position {
              margin-left: torem(3);
              font-size: torem(12);
            }
          }
          .hof_company {
            margin-top: torem(2);
            font-size: torem(10);
            color: #9389BC;
          }
        }

      }
    }
  }
  .mdt_partners {
    .flex_between {
      flex-wrap: wrap;
      padding: 0 torem(15);
    }
    .partners_img {
      width: torem(165);
      height: torem(60);
      margin-bottom: torem(15);
      background: #ffffff;
      &:nth-last-child(1){
        margin-bottom: 0;
      }
    }
  }
  .mdt_digitization {
    .digitization {
      flex-wrap: wrap;
      padding: 0 torem(15);
    }
    .digitization_img {
      width: torem(166);
      margin-bottom: torem(13);
      &:nth-last-child(1), &:nth-last-child(2) {
        margin-bottom: 0;
      }

    }
  }
  .mdt_with {
    text-align: center;
    font-size: torem(14);
    color: #FFFFFF;
    .with {
      .with_top {
        padding: 0 torem(30);
      }
      .with_bottom {
        position: relative;
        padding: torem(39) torem(15) torem(86) torem(45);
      }
      .with_qrcode {
        position: absolute;
        top: torem(22);
        left: torem(20);
        width: torem(108);
      }
      .with_card {
        margin-left: auto;
        text-align: left;
        width: torem(275);
        box-sizing: border-box;
        padding: torem(20) torem(0) torem(24) torem(55);
        background: linear-gradient(270deg, rgba(146, 13, 159, 0) 0%, #B622ED 100%);
        p {
          margin-bottom: torem(7);
          font-size: torem(13);
        }
        >p:nth-child(1) {
          margin-bottom: torem(12);
          font-size: torem(19);
        }
        >p:nth-last-child(1) {
          margin-bottom: 0;
        }
      }
    }
  }
  .mdt_bottom {
    &_img {
      width: 100%;
    }
  }
}
.title_box {
  padding: torem(55) 0 torem(20);
  .title_img {
    width: torem(39);
    height: torem(9);
  }
  .title_text {
    margin: 0 torem(10);
    color: #FFFFFF;
    .title {
      font-size: torem(21);
      line-height: 1;
      margin-bottom: torem(3);
    }
    .eng_title {
      opacity: .42;
      font-size: torem(9);
      line-height: torem(10);
    }
  }
}
.video_box {
  position: relative;
}
.video_box:after {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  content: '';
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.16);
}
.main_bg {
  position: relative;
  display: inline-block;
  padding: 0 torem(10) 0 torem(13);
  font-size: torem(12);
  line-height: torem(26);
  background: linear-gradient(270deg, #2E49FF 0%, #FF6FE4 100%);
  &::before {
    content: '';
    position: absolute;
    left: 0;
    width: torem(4);
    height: 100%;
    background: #FBA9FF;
  }
}
.colorEBB7FF {
  color: #EBB7FF;
}
.color_light {
  color: #FFFFFF;
  opacity: .39;
}
.big_img {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #000;
  align-items: center;
  display: flex;
  z-index: 3;
  img {
    width: 100%;
  }
}
</style>


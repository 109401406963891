<template>
  <div class="title_box flex_center">
    <img class="title_img" src="../../../static/img/digital/title_left.png" alt="" srcset="">
    <div class="title_text">
      <div class="title">{{ cnTitle }}</div>
      <div class="eng_title">{{ enTitle }}</div>
    </div>
    <img class="title_img" src="../../../static/img/digital/title_right.png" alt="" srcset="">
  </div>
</template>

<script>
export default {
  props: {
    cnTitle: { type: String, default: '' },
    enTitle: { type: String, default: '' }
  }
}
</script>

<style lang="scss">
@function torem($px){ //$px为需要转换的字号
    @return $px / 100 + rem; //100px为根字体大小
}
.title_box {
  padding: torem(55) 0 torem(20);
  .title_img {
    width: torem(39);
    height: torem(9);
  }
  .title_text {
    margin: 0 torem(10);
    color: #FFFFFF;
    .title {
      font-size: torem(21);
      line-height: 1;
      margin-bottom: torem(3);
    }
    .eng_title {
      opacity: .42;
      font-size: torem(9);
      line-height: torem(10);
    }
  }
}
</style>
